.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 780px) {
  .fc-toolbar-title {
    font-size: 1.2em !important;
  }
  .image-gallery-slide img {
    width: 100%;
    height: 200px;
  }
  .fc-dayGridMonth-button,
  .fc-timeGridWeek-button,
  .fc-timeGridDay-button {
    display: none !important;
  }
}
@media screen and (min-width: 781px) {
  .fc-toolbar-title {
    font-size: 1.5em !important;
  }
  .image-gallery-slide img {
    width: 100%;
    height: 200px;
  }
  #mobileDayGridMonthButton,
  #mobileTimeGridDayButton {
    display: none !important;
  }
}

.anchor {
  padding-top: 70px;
  margin-top: -70px;
}

.cardImage:hover {
  cursor: pointer; /* マウスカーソルを手の形にする */
  opacity: 0.6; /* ボタンの透明度を60%にする */
}

@media screen and (min-width: 900px) {
  .image-gallery-slide img {
    width: 100%;
    height: 400px;
  }
}

#homeHeaderImages {
  margin-top: -20px !important;
}

/* ライトモードの時のカラー */
:root {
  --sat-color: blue;
  --sun-color: red;
  --a-link-color: #0000ee;
  --a-link-visited: #551b8c;
  --fullCalendar-sat: #eaf4ff;
  --fullCalendar-sun: #ffeaea;
}
/* ダークモードの時のカラー */
:root[theme='dark'] {
  --sat-color: #6964fa;
  --sun-color: #fa6964;
  --a-link-color: #0bd;
  --a-link-visited: #3399ff;
  --fullCalendar-sat: inherit;
  --fullCalendar-sun: inherit;
}

td.fc-day-sat,
th.fc-day-sat {
  color: var(--sat-color);
}

td.fc-day-sun,
th.fc-day-sun {
  color: var(--sun-color);
}

main a:not([class]):link,
#fullCalendar a:link {
  color: var(--a-link-color);
}
main a:not([class]):visited,
#fullCalendar a:visited {
  color: var(--a-link-visited);
}

td.fc-day-sat {
  background-color: var(--fullCalendar-sat);
}
td.fc-day-sun {
  background-color: var(--fullCalendar-sun);
}
